.my_class {
  text-align-last: center;
  padding: 9px 25px;
  width: 200px;

  color: #000;
  border: 1px solid #3d8ecc !important;
  border-radius: 0;
  background-color: white;
  background-image: linear-gradient(45deg, transparent 50%, #bababa 50%),
    linear-gradient(135deg, #bababa 50%, transparent 50%);
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) calc(1em + 4px),
    calc(100% - 7px) calc(1em + 4px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;

  font: inherit;
  font-size: 12px;
  -webkit-appearance: none;

  &:focus {
    outline: 0;
  }
}

.Wizard_Content_Card {
  background: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 17px;
  margin-top: -50px;

  input,
  select {
    color: black;
    height: 30px;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.5px;
    border: 1px solid #dedede;
    border-radius: 5px;

    &::placeholder {
      color: rgba(black, 0.3);
    }
  }
  .css-g1d714-ValueContainer {
    max-height: 100px !important;
    overflow: scroll !important;
    overflow-x: hidden !important;
  }
}



input[type=checkbox] {
  outline: 2px solid black;
}



.completed {
  background-color: linear-gradient(105.16deg, #2a80c6 39.34%, #1fb3cd 83.14%) !important;
  color: white;
}


//For form headings
.form-heading {
  color: #000000;
  font-size: 18px;
  font-weight: 500;
}


//For card headings
.card-heading{
  background-color: #3D8ECC;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
}

.schedule-coupon-card {
  background-color: #63b1f1;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
}


//for validation error messages
.error-message {
  font-size: 12px;
  color: red;
}

.table-height{
  max-height: 450px;
  overflow: scroll;
}
.table-height::-webkit-scrollbar{
  display: none;
}

.retailers-data-table{
  max-height: 550px;
  overflow: scroll;
}

.retailers-data-table::-webkit-scrollbar{
  display: none;
}

.check-box {
  margin-left: 40px;
  border: 1px solid red;
}


// .react-select-container {
//   height: 50px;

//   .css-1s2u09g-control,
//   .css-1pahdxg-control {
//     border-radius: 5px;
//     border: 1px solid #dedede;
//     min-height: 51px !important;

//     .css-1rhbuit-multiValue {
//       background-color: rgba(#c4c4c4, 0.2);
//       border-radius: 5px;

//       div {
//         color: rgba(black, 0.3);
//         font-weight: 500;
//         font-size: 16px;
//       }
//     }

//     .css-1okebmr-indicatorSeparator {
//       width: 0 !important;
//     }

//     svg {
//       fill: black;
//     }

//     &:focus {
//       box-shadow: 0 0 0 px blue;
//     }
//   }

//   .css-6j8wv5-Input {
//     margin: 0;
//   }

//   input {
//     height: auto;
//   }

//   .css-g1d714-ValueContainer {
//     max-height: 50px !important;
//     overflow: scroll !important;
//     overflow-x: hidden !important;
//   }
//   .css-1hwfws3 {
//     height: 151px !important;
//     overflow: scroll !important;
//     overflow-x: hidden !important;
//   }
// }

form.example input[type=text] {
  padding: 10px;
  font-size: 17px;
  border: 1px solid grey;
  // border-right: none;
  float: left;
  width: 500px;
  background: white;
  margin-left: 100px;
  margin-top: 20px;
}



form.example button {
  // float: left;
  width: 10%;
  height: 20%;
  padding: 10px;
  background: white;
  color: black;
  font-size: 17px;
  border: 1px solid grey;
  border-left: none;
  cursor: pointer;
  margin-left: -10px;
  margin-top: 20px;
}


.search-form{
  display: flex;
  // flex-direction: row;
  // justify-content: space-between;
}


.search-img{
  height: 25px;
  width: 25px;
  margin-top: 30px;
  margin-left: 20px;
}

.cross-img{
  height: 25px;
  width: 25px;
  margin-top: 30px;
  margin-left: -80px;
}
.category_selection select{
  border-color: #3d8ecc !important;
  font-size: 18px;
}