$white: #fff;


.Second_Header {
    padding: 4px 0px;
    background-color: $white;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 20px 20px;
     .Second_Header_InnerBlock {
      max-height: 100%;
      height: 60px;
      .Profile {
        display: flex;
        align-items: center;
        img {
          border: 2px solid rgba(30, 30, 30, 0.1);
          border-radius: 100%;
          height: 32px;
          width: 32px;
          object-fit: cover;
        }
        span {
          display: block;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          margin-left: 9px;
        }
      }
      .user-nav{
        align-items: center;
        display: flex;
        //background-color: burlywood;
        align-self: stretch;

        &>* {

          padding:0 20px;
          cursor: pointer;
          height: 100%;
          display: flex;
          align-items: center;
        }

        &__icon{
          height: 55px;

        }
       
        &__user_photo{
          height: 55px;

        }

        &__icon-box {
          .btn-primary{
            color: black !important;
            background-color: #fff !important;
            border-color: #3d8ecc !important;
            font-size: 18px;

          }
          .dropdown-menu{

            max-height:160px !important;
            overflow-y:auto !important;
            overflow-x: hidden !important;
            scroll-behavior: smooth ;

          }


          .dropdown-toggle::after{
            margin-left:1.255em !important;

          } 
        }


        .Second_Header_Logo {
            img {
              max-width: 140px;
              height: auto;
              max-height: 43px;
              object-fit: contain;
            }
          }
      }
     
    }
  }